import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  ngOnInit(): void {
    this.http.get('/api/env').subscribe(result => {
      var firebaseConfig = {
        apiKey: result[1],
        authDomain: result[2],
        databaseURL: result[3],
        projectId: result[4],
        storageBucket: result[5],
        messagingSenderId: result[6],
        appId: result[7],
        measurementId: result[8],
      };
      firebase.initializeApp(firebaseConfig);
    })
  }

  constructor(private elementRef: ElementRef, private http: HttpClient){}

  public brewCLassName = 'brew'
  public androidCLassName = 'android'
  public githubCLassName = 'github'
  public linkedinCLassName = 'linkedin'

  openLinkedIn() {
    firebase.analytics().logEvent('LinkedIn');
    window.open("https://www.linkedin.com/in/eitvydas-norkus", "_blank");
  }

  openGitHub() {
    firebase.analytics().logEvent('GitHub');
    window.open("https://github.com/enorkus", "_blank");
  }

  openGooglePlay() {
    firebase.analytics().logEvent('GooglePlay');
    window.open("https://play.google.com/store/apps/developer?id=enorkus", "_blank");
  }

  openBrewTracker() {
    firebase.analytics().logEvent('BrewMon');
    window.open("http://brew.enorkus.com", "_blank");
  }

  expandButton() {

    this.brewCLassName += ' disappearAnimation'
    this.androidCLassName += ' disappearAnimation'
    this.githubCLassName += ' disappearAnimation'
    this.linkedinCLassName += ' disappearAnimation'

    setTimeout(()=>{
      this.brewCLassName += ' hidden'
      this.androidCLassName += ' hidden'
      this.githubCLassName += ' hidden'
      this.linkedinCLassName += ' hidden'
    }, 300)
  }

}
